<template>
  <div class="chart">
    <div class="chart-title">
      <Title :title="$t('plttoken.name')" />
    </div>
    <div class="chart-main-sm" v-show="size === 'xs'" />
    <div class="chart-main" v-show="size !== 'xs'" />
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getPltHolders } from "@/api/_common";
import Title from "@/components/SecondaryTitle.vue";
import { mergeRecursive, generateColors } from "@/utils/graph";
import { useMq } from "vue3-mq";

const theme = {};

export default {
  name: "PLT",
  components: {
    Title,
  },
  data() {
    return {
      size: null,
      pageSize: 10,
    };
  },
  watch: {
    $route: function () {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
  async mounted() {
    window.addEventListener("resize", this.onResize);
    const mq = useMq().current;
    this.size = mq;
    this.renderGraphs();
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize(e) {
      if (window.innerWidth < 576 && this.size !== "xs") {
        this.size = "xs";
      } else if (window.innerWidth > 576 && this.size === "xs") {
        this.size = "nxs";
      }
    },
    async renderGraphs() {
      const data = await getPltHolders({
        PageNo: 0,
        PageSize: this.pageSize,
      });
      const parsedData = data.PLTHolderInfos.map((element) => {
        if (element.Amount !== "0") {
          return {
            value: element.Amount,
            name: element.Address,
            ratio: element.Percent,
          };
        }
      }).filter((element) => element);

      const option = {
        title: {
          text: "PLT Holders",
          left: "24%",
          top: "5%",
        },
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `${params.name} : ${params.value} (${params.data.ratio})`;
          },
        },
        legend: {
          orient: "vertical",
          right: "3%",
          top: "25%",
        },
        series: [
          {
            name: "PLT Holders",
            type: "pie",
            radius: "55%",
            center: ["30%", "50%"],
            data: parsedData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            color: generateColors(
              getComputedStyle(
                document.getElementsByClassName("chart")[0]
              ).getPropertyValue("color"),
              this.pageSize
            ),
            label: {
              formatter: function (params) {
                return `${params.name.substr(0, 8)}...${params.name.substr(
                  34,
                  8
                )}`;
              },
            },
          },
        ],
      };
      const optionSm = {
        title: {
          show: false,
        },
        tooltip: {
          position: ["52%", "28%"],
          trigger: "item",
          formatter: function (params) {
            return `${params.name.substr(0, 4)}...${params.name.substr(
              38,
              4
            )} : ${params.data.ratio}`;
          },
        },
        legend: {
          orient: "vertical",
          top: "42%",
          left: "2%",
        },
        series: [
          {
            name: "PLT Holders xs",
            type: "pie",
            radius: "40%",
            center: ["30%", "20%"],
            data: parsedData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            color: generateColors(
              getComputedStyle(
                document.getElementsByClassName("chart")[0]
              ).getPropertyValue("color"),
              this.pageSize
            ),
            label: {
              show: false,
            },
          },
        ],
      };

      const merged = mergeRecursive(theme, option);
      const chart = echarts.init(
        document.getElementsByClassName("chart-main")[0],
        null,
        { renderer: "svg" }
      );
      chart.setOption(merged);
      const mergedSm = mergeRecursive(theme, optionSm);
      const chartSm = echarts.init(
        document.getElementsByClassName("chart-main-sm")[0],
        null,
        { renderer: "svg" }
      );
      chartSm.setOption(mergedSm);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/chart.scss";
div {
  max-width: 100%;
}
:deep(svg) {
  width: 100%;
  height: 100%;
}
</style>
