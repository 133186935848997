<template>
  <div class="common-title">
    <img v-if="img" :src="img" class="nft-img" />
    <img v-else src="@/assets/image/plt.svg" class="plt-icon" />
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: ["title", "img"],
};
</script>

<style lang="scss" scoped>
.common-title {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 100%;
  span {
    font-size: 32px;
    font-family: Avenir-Heavy, Avenir;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.8);
    line-height: 44px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
    max-width: 100%;
    text-align: left;
    @media (max-width: $breakpoint-xl) {
      font-size: 1.5em;
    }
  }
  .plt-icon {
    width: 40px;
    height: auto;
    @media (max-width: $breakpoint-xl) {
      display: none;
    }
  }
  .nft-img {
    width: 80px;
    height: auto;
    @media (max-width: $breakpoint-xl) {
      margin-bottom: 40px;
    }
  }
}
</style>
